<template>
    <div
    class="horizontal-layout"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height:inherit"
  >
      <div class="auth-btn">
          <b-button
              size="sm"
              variant="outline-secondary"

              @click="modalAuth = true"
          >
            <i class="fas fa-lock"></i>
          </b-button>
          <b-button
              size="sm"
              class="ml-1"
              variant="outline-secondary"
              v-if="this.admin"
          >
            <router-link :to="{ name: 'admin' }" >
              <i class="fas fa-cog"></i>
            </router-link>
          </b-button>

          <b-button
              size="sm"
              class="ml-1"
              variant="outline-secondary"
              @click="modalCertificate = true"
              v-if="this.admin"
          >
            <i class="fas fa-file"></i>
          </b-button>
          <b-button
              size="sm"
              class="ml-1"
              variant="outline-secondary"
              @click="modalUser = true"
              v-if="this.admin"
          >
            <i class="fas fa-user"></i>
          </b-button>

        <b-modal
            id="user-modal"
            size="sm"
            centered
            v-model="modalUser"
            title="Création d'un utilisateur"
            hide-footer
        >
          <div class="row">
            <div class="col-12 col-lg-10 mx-auto py-3">
              <p class="text-center" style="color: red" v-if="errorUser !== ''">{{ this.errorUser }}</p>
              <b-form @submit.prevent="createUser">
                <b-form-group label="Email" label-for="idEmail">
                  <b-form-input type="text" id="idEmail" v-model="email" placeholder="deepblock@deepblock.com"></b-form-input>
                </b-form-group>
                <b-form-group label="Nom" label-for="idName">
                  <b-form-input type="text" id="idName" v-model="lastName" placeholder="Doe"></b-form-input>
                </b-form-group>
                <b-form-group label="Prénom" label-for="idLastname">
                  <b-form-input type="text" id="idLastname" v-model="firstName" placeholder="John"></b-form-input>
                </b-form-group>
                <b-form-group label="Nom de la société" label-for="idCompany">
                  <b-form-input type="text" id="idCompany" v-model="company" placeholder="Deepblock"></b-form-input>
                </b-form-group>
                <b-button :disabled="userLoading" type="submit" variant="primary">
                  <span v-if="!userLoading">Création de l'utilisateur</span>
                  <span v-else>Chargement ...</span>
                </b-button>
                <b-button v-if="newPublicKey !== ''" type="button" variant="primary" class="mt-1" @click="copyText(newPublicKey)">
                  <span >Copier la clef publique</span>
                </b-button>
                <b-button v-if="newPrivateKey !== ''" type="button" variant="primary" class="mt-1" @click="copyText(newPrivateKey)">
                  <span >Copier la clef privée</span>
                </b-button>
              </b-form>
            </div>
          </div>
        </b-modal>


        <b-modal
            id="certificate-modal"
            size="sm"
            centered
            v-model="modalCertificate"
            title="Gestion des attestations"
            hide-footer
        >
          <div class="row">
            <div class="col-12 col-lg-10 mx-auto py-3">
              <p class="text-center" style="color: red" v-if="errorCertif !== ''">{{ this.errorCertif }}</p>
              <b-form @submit.prevent="reloadCertificate">
                <b-form-group label="ID du/des attestation(s)" label-for="idCertificate">
                  <b-form-input type="text" id="idCertificate" v-model="idCertificate" placeholder="ID Simple ou ID,ID,ID"></b-form-input>
                </b-form-group>
                <b-button :disabled="certifLoading" type="submit" variant="primary">
                  <span v-if="!certifLoading">Reload Certificate</span>
                  <span v-else>Loading ...</span>
                </b-button>
              </b-form>
            </div>
          </div>
        </b-modal>

        <b-modal
            id="auth-key-modal"
            size="lg"
            centered
            v-model="modalAuth"
            title="Auth Key"
            hide-footer
        >
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto py-4">
              <p class="text-center" style="color: red" v-if="error !== ''">{{ this.error }}</p>
              <b-form @submit.prevent="onLogin">
                <b-form-group label="Public Key" label-for="public-key">
                  <b-form-textarea id="public-key" v-model="publicKey" placeholder="Enter Public Key"></b-form-textarea>
                </b-form-group>

                <b-form-group label="Private Key" label-for="private-key">
                  <b-form-textarea id="private-key" v-model="privateKey" placeholder="Enter Private Key"></b-form-textarea>
                </b-form-group>

                <b-button type="submit" variant="primary">Login</b-button>
              </b-form>
            </div>
          </div>
        </b-modal>



      </div>
    <b-row>
      <b-col lg="3" />
      <b-col lg="3">
        <div class="text-center mt-4 mb-2">
          <img class="mt-2" src="/logo.png" width="70%">
        </div>
      </b-col>
      <b-col lg="3">
        <h1 class="text-center mt-4">
          <a href="/">OpenLogs Explorer</a>
        </h1>
        <div class="text-center menu-links mb-2">
          <span>release SuperBlock</span>
        </div>
        <div class="text-center menu-links mb-4">
          <router-link :to="{ name: 'explorer' }">
            Explorer
          </router-link>
          -
          <router-link :to="{ name: 'map' }">
            Carte des nœuds
          </router-link>
          -
          <router-link :to="{ name: 'decrypt' }">
            Déchiffrement
          </router-link>
          <span v-if="this.login">-</span>
          <router-link :to="{ name: 'my-explorer' }" v-if="this.login">
              Vos nœuds
          </router-link>
        </div>
      </b-col>
    </b-row>
    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
        style="padding-top: 0 !important;"
      >
        <template
          v-for="(index, name) in $slots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>

    <slot name="customizer" />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BAvatar,
  BBadge, BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BMedia, BMediaAside, BMediaBody, BFormTextarea, BFormText,
  BNavbar,
  BRow,
  BTable
} from 'bootstrap-vue'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'
import axios from "axios";
import { onUnmounted } from '@vue/composition-api'

// Content Renderer
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import useLayoutHorizontal from './useLayoutHorizontal'
import HorizontalNavMenu from './components/horizontal-nav-menu/HorizontalNavMenu.vue'

// Vertical Menu
import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import mixinLayoutHorizontal from './mixinLayoutHorizontal'
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    AppBreadcrumb,
    // AppNavbarHorizontalLayout,
    // AppNavbarHorizontalLayoutBrand,
    AppFooter,
    HorizontalNavMenu,
    BRow,
    BCol,
    BFormTextarea,
    BTable,
    BBadge,
    BFormText,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    StatisticCardVertical,
    VueApexCharts,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BNavbar,

    // Content Renderer
    LayoutContentRendererDefault,
    LayoutContentRendererLeft,
    LayoutContentRendererLeftDetached,

    // Vertical Menu
    VerticalNavMenu,
  },
  data() {
    return {
      modalAuth: false,
      modalCertificate: false,
      modalUser: false,
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      error: "",
      publicKey: '',
      privateKey: '',
      idCertificate: '',
      login: false,
      admin: false,
      errorCertif: '',
      errorUser: '',
      certifLoading: false,
      userLoading: false,
      newPrivateKey: "",
      newPublicKey: "",

    }
  },
  mounted() {
    this.publicKey = localStorage.getItem('publicKey') || '';
    this.privateKey = localStorage.getItem('privateKey') || '';
    if (this.privateKey !== '' && this.publicKey !== '') {
      this.isLogin();
      this.isAdmin();
    }
  },
  mixins: [mixinLayoutHorizontal],
  setup() {
    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
    } = useAppConfig()

    // Vertical Menu
    const {
      isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const {
      navbarMenuTypeClass,
      layoutClasses,
      footerTypeClass,
    } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    return {
      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
    }
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  methods: {
    copyText(text) {
      navigator.clipboard.writeText(text);
      alert("Contenu copié avec succès !");
    },
    isLogin() {
      axios.get("https://sb.api.dev.shurisko.fr/api/myself", {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then((data) => {
        this.login = true;
      }).catch((err) => {
        this.login = false;
      });
    },
    reloadCertificate() {
      this.certifLoading = true;
      let id = `https://sb.api.dev.shurisko.fr/api/admin/certificate/${this.idCertificate}`;
      axios.post(id, {}, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then((data) => {
        if (!data.data.success)
          this.errorCertif = data.data.error;
        else
          this.modalCertificate = false;
        this.certifLoading = false;
        this.errorCertif = "";
      }).catch((err) => {
        this.errorCertif = err;
        this.certifLoading = false;
      });
    },
    createUser() {
      this.userLoading = true;
      this.newPublicKey = ""
      this.newPrivateKey = ""
      axios.post("https://sb.api.dev.shurisko.fr/api/admin/user", {
        email: this.email,
        firstname: this.firstName,
        lastname: this.lastName,
        company: this.company
      }, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then((data) => {

        this.newPublicKey = data.data.data.publicKey;
        this.newPrivateKey = data.data.data._plainPrivateKey;

        // this.lastName = "";
        // this.firstName = "";
        // this.company = "";
        // this.email = "";

        this.userLoading = false;
        this.errorUser = "";
      }).catch((err) => {
        this.errorUser = err;
        this.userLoading = false;
      });
    },
    onLogin() {
      axios.get("https://sb.api.dev.shurisko.fr/api/myself", {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then((data) => {
        this.modalAuth = false;
        localStorage.setItem('publicKey', this.publicKey);
        localStorage.setItem('privateKey', this.privateKey);
        this.login = true;
        this.error = "";
      }).catch((err) => {
        localStorage.removeItem('publicKey');
        localStorage.removeItem('privateKey');
        this.error = "Error: Bad Key !"
      });
    },
    isAdmin() {
      axios.get("https://sb.api.dev.shurisko.fr/api/admin", {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then((data) => {
        if (data.data.success) {
          this.admin = true;
        }
      }).catch((err) => {
        this.admin = false;
      });
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";

a {
  color:#FFF;
}

.auth-btn {
  position: fixed; /* Fixe la position du div */
  top: 0; /* 0px du haut */
  right: 0; /* 0px à droite */
  margin: 10px; /* Ajoute un peu d'espace autour du bouton */
  z-index: 1;
}

.menu-links a {
  color: #f0f0f0;
}
</style>
